<template>
  <div id="login-box" class="login-page">
    <div class="logo">
      <img src="/liberrex_white.png" width="200"/>
      <div class="mt-3">{{ "Kiosk" }}</div>
    </div>
    <div v-if="!loginVerificationShow" class="code-box">
      <div class="code-text">{{ codeToDisplay }}</div>
    </div>
    <div class="verif-login" v-else>
      <div>
        <div class="mb-2">{{ $t("Auth.LoginVerif") }}</div>
        <div>{{ $t("Auth.OriginRequest") + dataPusher.business.name }}</div>
        <div>{{ $t("Auth.KioskName") + dataPusher.kiosk.name }}</div>
      </div>

      <vs-button
          class="btn-block btn btn-outline-light btn-lg submit-button mt-5"
          type="submit"
          @click.stop.prevent="loginFromPusher"
      >
        {{ $t("Auth.Approve") }}
      </vs-button>

      <vs-button
          class="btn-block btn btn-outline-light btn-lg submit-button mt-4"
          type="submit"
          @click.stop.prevent="declineRequest"
      >
        {{ $t("Auth.Decline") }}
      </vs-button>
    </div>
    <div v-if="!refreshButtonStatus && !loginVerificationShow" class="time-box" :key="componentKey">
      <div id="countdown">
        <div id="countdown-number"></div>
        <svg>
          <circle r="36" cx="40" cy="40"></circle>
        </svg>
      </div>
      <vs-button
          class="btn-block btn btn-outline-light btn-lg submit-button mt-1 redirect-link"
          type="submit"
          @click.stop.prevent="GotoV2"
      >
         {{$t('Auth.redirect')}} v2
      </vs-button>
    </div>
    <div v-else class="button-refresh">
      <vs-button
          class="btn-block btn btn-outline-light btn-lg submit-button mt-5"
          type="submit"
          @click.stop.prevent="refreshScreen"
      >
        {{ $t("Auth.Refresh") }}
      </vs-button>
    </div>
    <LoadingPopup
        :active="loading.active"
        :message="loading.message"
    ></LoadingPopup>
    <Popup
        :message="popup.message"
        :hint="popup.hint"
        :title="popup.title"
        :type="popup.type"
        :confirmationButton="popup.confirmation"
        :active.sync="popup.active"
        @confirm="popup.callback ? popup.callback : hidePopup()"
    >
    </Popup>
  </div>
</template>
<style>
body {
  font-family: Arial;
  color: white;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  opacity: 0.8;
}

.split {
  height: 100%;
  width: 50%;
  position: fixed;
}

.left {
  left: 0;
  background-color: rgba(255, 255, 255, 0);
}

.right {
  right: 0;
  background-image: linear-gradient(to right, #008fc9, #0478a5);
  padding: 50px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 50px;
  transform: translate(-50%, -50%);
  text-align: left;
}

.redirect-link {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 35px;
  width: auto;
}

@media screen and (max-width: 600px) {
  .left {
    display: none;
  }

  .split {
    width: 100%;
  }
}
</style>
<script>
import LbrxButton from "../../components/buttons/Button.vue";
import {mapState, mapActions} from "vuex";
import LoadingPopup from "../../components/popups/Loading";
import Popup from "../../components/popups/Popup.vue";
import {kioskService} from "../../_services";

export default {
  name: "Login",
  data: () => ({
    msg: "Login",
    key: "",
    secret: "",
    requestFailed: false,
    errors: [],
    loading: {
      active: false,
      message: "",
    },
    popup: {
      active: false,
      title: "",
      message: "",
      hint: "",
      type: "",
      confirmation: "",
      callback: null,
    },
    server: localStorage.getItem("server")
        ? localStorage.getItem("server")
        : "tn",
    code: "",
    codeToDisplay: "",
    loginVerificationShow: false,
    dataPusher: {},
    componentKey: 0,
    countdownInerval: null,
    requestLimit: 0,
    refreshButtonStatus: false
  }),
  watch: {
    code: function () {
      // watch it
      this.$pusher.channel("kiosk-auth-" + this.code).listen(
          ".kiosk-auth",
          function (data) {
            console.log("listening ....")
            this.loginVerificationShow = true;
            this.dataPusher = data;
          }.bind(this)
      );
    },
  },
  methods: {
    GotoV2() {
      localStorage.setItem('kiosk-version', 'v2')
      window.location.href = process.env.VUE_APP_V2_URL
    },
    refreshScreen() {
      location.reload(true);
    },
    selectServer(sv) {
      localStorage.setItem("server", sv);
      this.$router.go();
    },
    loginFromPusher() {
      this.pusherLogin(this.dataPusher);
    },
    declineRequest() {
      this.loginVerificationShow = false;
      this.refreshButtonStatus = false;
      this.requestLimit = 0;
      this.dataPusher = {};
      this.forceRerender();
      this.generateAuthCode();
    },
    checkForm: function (e) {
      this.errors = [];
      if (!this.key) {
        this.errors.push(
            this.$t("Errors.Required", {field: this.$t("Kiosk.App.Key")})
        );
      } else if (!this.secret)
        this.errors.push(
            this.$t("Errors.Required", {field: this.$t("Kiosk.App.Key")})
        );
      else {
        this.handleSubmit();
        //this.$router.push('/dashboards/classic-dashboard');
      }
      if (!this.errors.length) return true;
      e.preventDefault();
    },
    /* eslint-disable */
    validEmail: function (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(email);
    },
    /* eslint-disable */
    submit() {
      if (this.validEmail(this.key)) {
        this.handleSubmit();
      } else {
        this.errors.push(
            this.$t("Errors.Invalid", {field: this.$t("Auth.Email")})
        );
      }
    },
    ...mapActions("Kiosk", ["login", "autoLogin", "logout", "pusherLogin"]),
    handleSubmit() {
      this.requestFailed = false;
      this.submitted = true;
      const {key, secret} = this;
      if (key && secret) {
        this.showLoading("Please wait, we are verifying your credentials!");
        //this.$vs.loading({ container: '#login-box', scale: 0.6 });
        this.login({key, secret})
            .catch(
                function (data) {
                  this.showPopup(
                      "danger",
                      "Ouups!",
                      "Nous ne pouvons pas vous connecter",
                      "Veuillez vérifier vos identifiants de connexion et réessayer",
                      "Fermer",
                      this.hidePopup
                  );
                  this.requestFailed = true;
                }.bind(this)
            )
            .catch(
                function (ex) {
                  console.log(ex);
                }.bind(this)
            )
            .finally(
                function () {
                  this.hideLoading();
                }.bind(this)
            );
      }
    },
    showLoading(message) {
      this.loading = {
        active: true,
        message: message,
      };
    },
    hideLoading() {
      this.loading = {active: false, message: ""};
    },
    showPopup(type, title, message, hint, confirmation, callback) {
      this.popup = {
        active: true,
        title: title,
        message: message,
        hint: hint,
        type: type,
        confirmation: confirmation,
        callback: callback,
      };
    },
    hidePopup() {
      this.popup = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
    },
    generateAuthCode() {
      this.requestLimit += 1;
      kioskService
          .generateAuthCode()
          .then(
              function (res) {
                this.code = res.code;
                this.codeToDisplay = res.code
                    .toString()
                    .replace(/(\d{3})(\d{3})/, "$1-$2");
              }.bind(this)
          )
          .catch(
              function (err) {
                console.log(err);
              }.bind(this)
          );
    },
    executeTimer() {
      let countdownNumberEl = document.getElementById("countdown-number");
      let countdown = 180;

      countdownNumberEl.textContent = countdown;

      this.countdownInerval = setInterval(
          function () {
            if (countdown == 1) {
              if (this.requestLimit >= 2) {
                clearInterval(this.countdownInerval);
                this.refreshButtonStatus = true;
              } else {
                this.generateAuthCode();
              }
            }
            countdown = --countdown <= 0 ? 180 : countdown;

            countdownNumberEl.textContent = countdown;
          }.bind(this),
          1000
      );
    },
    forceRerender() {
      this.componentKey += 1;
      setTimeout(() => {
        clearInterval(this.countdownInerval);
        this.executeTimer();
      }, 500);
    },
  },
  computed: {
    ...mapState("Kiosk", ["status"]),
    ...mapState({
      kiosk_info: (state) => state.Kiosk.kiosk,
    }),
    inputValid() {
      if (this.key && this.secret && !this.requestFailed) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    // reset login status
    if (this.kiosk_info) this.$router.push("/home");
    //this.logout();
  },
  mounted() {
    if (localStorage.getItem('kiosk-version') === 'v2') {
      window.location.href = process.env.VUE_APP_V2_URL
    }
    this.$i18n.locale = "fr";
    localStorage.setItem("Language", "fr");

    if (this.$route.params.key && this.$route.params.token) {
      console.log({
        key: this.$route.params.key,
        token: this.$route.params.token,
      });
      this.showLoading(
          "Veuillez patienter, nous vérifions vos informations d'identification !"
      );
      this.autoLogin({
        key: this.$route.params.key,
        token: this.$route.params.token,
      })
          .catch(
              function (data) {
                this.showPopup(
                    "danger",
                    "Ouups!",
                    "Nous ne pouvons pas vous connecter",
                    "Please check your login credentials and try again",
                    "Fermer",
                    this.hidePopup
                );

                this.requestFailed = true;
              }.bind(this)
          )
          .catch(
              function (ex) {
                console.log(ex);
              }.bind(this)
          )
          .finally(
              function () {
                this.hideLoading();
              }.bind(this)
          );
    }
    if (localStorage.getItem("host") != null) {
      if (
          confirm(
              "You changed the original host. If you want to return to the default one click Ok."
          )
      ) {
        localStorage.removeItem("host");
      }
    }
    if (localStorage.getItem("kiosk") == null || localStorage.getItem("kiosk") == "null") {
      this.generateAuthCode();
      // beginning the countdown timer
      this.executeTimer();
    }

  },
  beforeDestroy() {
    clearInterval(this.countdownInerval);
  },
  components: {
    LoadingPopup,
    LbrxButton,
    Popup,
  },
};
</script>

<style scoped>
.login-page {
  background-color: #3d3d3d;
  width: 100vw;
  height: 100vh;
}

.logo {
  text-align: center;
  margin-top: 40px;
  font-family: "BaiJamjuree";
  font-size: 19px;
}

.code-box {
  width: 100%;
  height: 100%;
  display: flex;
}

.verif-login {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  color: #eee;
  font-size: 1.2rem;
}

.code-text {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: "BaiJamjuree";
  font-size: 7rem;
}

@media screen and (min-width: 1440px) {
  .verif-login {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    color: #eee;
    font-size: 1.6rem;
  }

  .code-text {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: "BaiJamjuree";
    font-size: 11rem;
  }
}

@media screen and (min-width: 2560px) {
  .verif-login {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -80%);
    color: #eee;
    font-size: 1.9rem;
  }

  .code-text {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: "BaiJamjuree";
    font-size: 14rem;
  }
}

.time-box {
  width: 100%;
  height: 100%;
  display: flex;
}

.time-object {
  margin: auto;
  background-color: #49b7ff;
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

/* timer part */
#countdown {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 100px;
  height: 80px;
  width: 80px;
  text-align: center;
}

#countdown-number {
  color: #49b7ff;
  display: inline-block;
  line-height: 80px;
}

svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

svg circle {
  stroke-dasharray: 226px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 6px;
  stroke: #49b7ff;
  fill: none;
  animation: countdown 180s linear infinite forwards;
}

.button-refresh {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 60px;
  height: 100px;
  text-align: center;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 226px;
  }
}
</style>
